import React, { useState, useEffect } from "react";
import styled from "styled-components";
import OneXButton from "../OneX/OneXButton";
import OneXIcon from "../OneX/OneXIcon";
import EnsightenAnalytics from "../Utility/EnsightenAnalytics";
import { useRecoilValue } from "recoil";
import {
  selectedAgreementsState,
  emailState,
  staticAppConfig,
  successfulMatchState,
  hasAccountState
} from "../AppData/Recoil";
import { useNavigate, Navigate } from "react-router-dom";


const PageContainer = styled.div`
  text-align: center;
`;

const IdSuccess = () => {
  const [init, setInit] = useState(false);

  const selectedAgreements = useRecoilValue(selectedAgreementsState);
  const email = useRecoilValue(emailState);
  const hasAccount = useRecoilValue(hasAccountState);
  const succesfulMatch = useRecoilValue(successfulMatchState);

  const navigate = useNavigate();

  useEffect(() => {
    if (!init) {
      window.scrollTo(0, 0);
      EnsightenAnalytics.pushScreenChangeToAnalytics("idCardsSuccess");
      setInit(true);
    }
  }, [init, navigate]);

  const handleSuccessBtn = () => {
    if(hasAccount){
      EnsightenAnalytics.pushScreenChangeToAnalytics("sendFromSuccessToStateFarmLogin");
      window.location.assign('https://proofing.statefarm.com/login-interceptor');
    }
    else {
      EnsightenAnalytics.pushScreenChangeToAnalytics("sendFromSuccessToStateFarmRegistration");
      window.location.assign('https://apps.statefarm.com/CustomerRegistrationUI/');
    }
  }

  return !succesfulMatch ? (<Navigate to={staticAppConfig.routes.landing} />) : (
    <div className={"-oneX-col-lg-6 -oneX-col-md-8 -oneX-col-sm-10"}>
      <PageContainer id={"success"} >
        <div className={"-oneX-row -oneX-col-11"} style={{ marginTop:"65px" }}>
          <OneXIcon id={"successIcon"} type={"SUCCESS_24"} bounds={"47px"}/>
          <div className="-oneX-heading--h1" id={"success-header"} style={{ marginLeft:"10px" }}>
            All done
          </div>
        </div>


        <div className={"-oneX-body--intro"} style={{ marginBottom: "48px", marginTop: "48px", textAlign: "left" }}>
          {selectedAgreements.length > 1
            ? `Your ID Cards will be sent to ${email}. If you don’t get your ID cards from us in a few minutes, check your spam folder or try resending your cards.`
            : `Your ID Card will be sent to ${email}. If you don’t get your ID card from us in a few minutes, check your spam folder or try resending your card.`}
        </div>

        <div style={{ marginBottom: "48px", marginTop: "48px" }}>
          <OneXButton id={"success-primary-button"} type={"primary"} clickHandler={handleSuccessBtn}>
          {hasAccount ? "Log in" : "Create Account"}
          </OneXButton>
        </div>
      </PageContainer>
    </div>
  );
};

export default IdSuccess;
