import React, { useLayoutEffect, useRef } from "react";
import PropTypes from "prop-types";

const TYPES = {
  row: "-oneX-checkbox-group ",
  stacked: "-oneX-checkbox-group -oneX--stacked ",
  acknowledgement: "",
};

export const OneXCheckbox = ({
  id,
  checked,
  disabled,
  label,
  value,
  changeHandler,
  effectiveDate,
  expirationDate,
  desktopView,
}) => {
  const wrapperRef = useRef(null);

  useLayoutEffect(() => {

    const currentRef = wrapperRef.current;

    window.oneX.addElement(currentRef);

    return () => {
      window.oneX.removeElement(currentRef);
    }

  }, []);

  return (
    <div className="-oneX-checkbox-item" ref={wrapperRef}>
      <input
        id={`${id}-input`}
        type="checkbox"
        value={value}
        className="-oneX-checkbox"
        checked={checked}
        disabled={disabled}
        onChange={(e) => {
          changeHandler(e.target.value);
        }}
      />
      <label key={`${id}-label`} htmlFor={`${id}-input`}>
        {desktopView ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "83.3%",
            }}
          >
            <div>{label}</div>
            <div>{effectiveDate + " to " + expirationDate}</div>
          </div>
        ) : (
          <div>
            <div>{label}</div>
            <div>{effectiveDate + " to " + expirationDate}</div>
          </div>
        )}
      </label>
    </div>
  )
}

const OneXCheckboxGroup = ({
  id,
  className,
  options,
  changeHandler,
  type,
  groupLabel,
  desktopView,
}) => {
  const wrapperRef = useRef(null);

  useLayoutEffect(() => {

    const currentRef = wrapperRef.current;

    window.oneX.addElement(currentRef);

    return () => {
      window.oneX.removeElement(currentRef);
    }

  }, []);

  return (
    <fieldset className="-oneX-fieldset" ref={wrapperRef}>
      <legend className="-oneX-legend">{groupLabel}</legend>
      <div id={"checkBoxGroup" + id} className={TYPES[type] + className}>
        {options.map((option, index) => {
          return (
            <OneXCheckbox
              key={`${option.docId}-${index}`}
              id={`${option.docId}-checkbox`}
              value={option.docId}
              changeHandler={changeHandler}
              label={option.policyDescription}
              effectiveDate={option.effectiveDate}
              expirationDate={option.expirationDate}
              desktopView={desktopView}
            />
          );
        })}
      </div>
    </fieldset>
  );
};

OneXCheckboxGroup.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  changeHandler: PropTypes.func,
  type: PropTypes.oneOf(Object.keys(TYPES)),
  groupLabel: PropTypes.string,
};

export default OneXCheckboxGroup;
