  const hagertyComponent = ({args}) => {
    // for some reason it really doesn't like array of length 1, it was giving me an error about not being an array, if statement work around here
    if (args.length === 1) {
        return (<div>{args[0].policyDescription}</div>);
    } else {
    const hagertyList = args.map((hagertyCards) => {

        return <HagertyListItem descr={hagertyCards} />
    });

    return (<div>{hagertyList}</div>);
    }
    }

    function HagertyListItem(descr) {
        return (
                <p>
                    {descr.policyDescription}
                </p>
        );
    }

    
  export default hagertyComponent;