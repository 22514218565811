import React, { useState, useEffect } from "react";
import OneXButton from "../OneX/OneXButton";
import EnsightenAnalytics from "../Utility/EnsightenAnalytics";
import { useMediaQuery } from "@uidotdev/usehooks";
import OneXSpecs from "../OneX/OneXSpecs";

const Error = () => {
  const [init, setInit] = useState(false);

  const isMobile = useMediaQuery(OneXSpecs.DEVICE_MEDIA_QUERIES.MOBILE);
  const isTablet = useMediaQuery(OneXSpecs.DEVICE_MEDIA_QUERIES.TABLET);
  const isDesktop = useMediaQuery(OneXSpecs.DEVICE_MEDIA_QUERIES.DESKTOP);

  useEffect(() => {
    if (!init) {
      EnsightenAnalytics.pushScreenChangeToAnalytics("error");
      document.title = "Get insurance ID card - Technical Error";
      setInit(true);
    }
  }, [init]);

  const buttonHandler = () => {
    window.location.href = "https://www.statefarm.com/";
  }

  return (
    <div className={"-oneX-col-lg-6 -oneX-col-md-8 -oneX-col-sm-10"}>
      <h1 style={{ marginBottom: "28px", marginTop: isDesktop ? "56px" : isTablet ? "48px" : isMobile ? "40px" : "40px" }}>
        Technical Error
      </h1>
      <div className={"-oneX-body--intro"} style={{ marginBottom: "38px" }}>
        Sorry, there was an issue processing your request. Please contact your agent if you need immediate access to this document.
      </div>
      <OneXButton
        id="RtH_Btn"
        type={"primary"}
        onMouseDown={buttonHandler}
      >
        Return to Homepage
      </OneXButton>
    </div>
  );
};

export default Error;
