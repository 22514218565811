import React from "react";
import { Routes, BrowserRouter, Route, Navigate } from "react-router-dom";
import GetIdCard from "./Templates/GetIdCard";
import DocUnavailable from "./Templates/DocUnavailable";
import Error from "./Templates/Error";
import IdSuccess from "./Templates/IdSuccess";
import LoginRequired from "./Templates/LoginRequired";
import IdCardsList from "./Templates/IdCardsList";
import OneXPageContainer from "./OneX/OneXPageContainer";
import { staticAppConfig } from "./AppData/Recoil";

const ApplicationContainer = () => {

  return (
    <OneXPageContainer>
      <BrowserRouter>
      <Routes>
        <Route exact path={'/'} element={<Navigate to={staticAppConfig.routes.landing}/>} />
        <Route exact path={staticAppConfig.routes.landing} element={<GetIdCard />} />
        <Route exact path={staticAppConfig.routes.noCards} element={<DocUnavailable />} />
        <Route exact path={staticAppConfig.routes.error} element={<Error />} />
        <Route exact path={staticAppConfig.routes.cardsList} element={<IdCardsList />} />
        <Route exact path={staticAppConfig.routes.success} element={<IdSuccess />} />
        <Route exact path={staticAppConfig.routes.login} element={<LoginRequired />} />
        <Route exact path={staticAppConfig.routes.shapeError} element={<Error/>} />
      </Routes>
      </BrowserRouter>
    </OneXPageContainer>
  );
};

export default ApplicationContainer;
