import React, { useState, useEffect } from "react";
import OneXButton from "../OneX/OneXButton";
import OneXCheckboxGroup from "../OneX/OneXCheckbox";
import HagertyComponent from "../AppData/HagertyComponent";
import styled from "styled-components";
import { postSelection } from "../Utility/API";
import EnsightenAnalytics from "../Utility/EnsightenAnalytics";
import Flex from "../LAUI/Flex";
import OneXPageLoader from "../OneX/OneXPageLoader";
import {
  pageLevelErrorState,
  agreementsState,
  selectedAgreementsState,
  waitingForResponseState,
  emailState,
  emailSentState,
  staticAppConfig,
  successfulMatchState,
  jwtState,
  hasAccountState,
  hagertyAgreementsState
} from "../AppData/Recoil";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { useNavigate, Navigate } from "react-router-dom";
import { useMediaQuery } from "@uidotdev/usehooks";
import OneXSpecs from "../OneX/OneXSpecs";

const ContinueDisclaimer = ({ isManyCards }) => {
  return (
    <div style={{ marginLeft: "0px", textAlign: "left" }}
      className="-oneX-body--intro-sm"
    >
      {isManyCards
        ? "By clicking “Continue,” you’re agreeing to receive emails from State Farm for your Auto ID card(s)."
        : "By clicking “Continue,” you’re agreeing to receive an email from State Farm for your Auto ID card."}
    </div>
  );
};

const SubHeadingDiv = styled.div`
  margin-bottom: 57px;
  text-align: left;
`;

const IdCardsList = () => {

  const [init, setInit] = useState(false);
  const [selectionCnt, setSelectionCnt] = useState(0);
  const setPageLevelError = useSetRecoilState(pageLevelErrorState);
  const setWaitingForResponse = useSetRecoilState(waitingForResponseState);
  const setEmail = useSetRecoilState(emailState);
  const setEmailSent = useSetRecoilState(emailSentState);
  const waitingForResponse = useRecoilValue(waitingForResponseState);
  const agreements = useRecoilValue(agreementsState);
  const jwt = useRecoilValue(jwtState);
  const [selectedAgreements, setSelectedAgreements] = useRecoilState(selectedAgreementsState);
  const noAgreements = agreements === undefined || agreements.length === 0;
  const successfulMatch = useRecoilValue(successfulMatchState);
  const setHasAccount = useSetRecoilState(hasAccountState);
  const navigate = useNavigate();
  const hagertyAgreements = useRecoilValue(hagertyAgreementsState);

  const isMobile = useMediaQuery(OneXSpecs.DEVICE_MEDIA_QUERIES.MOBILE);
  const isTablet = useMediaQuery(OneXSpecs.DEVICE_MEDIA_QUERIES.TABLET);
  const isDesktop = useMediaQuery(OneXSpecs.DEVICE_MEDIA_QUERIES.DESKTOP);

  useEffect(() => {
    if (!init) {
      window.scrollTo(0, 0);
      if (noAgreements) {
        setPageLevelError(404);
      }
      EnsightenAnalytics.pushScreenChangeToAnalytics("idCardsList");
      document.title = "Get insurance ID card - Which ID cards would you like to receive?";
      setInit(true);
    }
  }, [init, navigate, noAgreements, setPageLevelError]);

  const handleClick = (event) => {
    if (selectedAgreements) {
      postSelection(setWaitingForResponse, setPageLevelError, setEmail, setEmailSent, navigate, selectedAgreements, jwt, setHasAccount);
    }
  };

  if (waitingForResponse) {
    return (
      <Flex style={{ marginTop: "105px", marginBottom: "105px" }}>
        <OneXPageLoader />
      </Flex>
    );
  }

  if (!successfulMatch) {
    return <Navigate to={staticAppConfig.routes.landing} />
  }

  if (hagertyAgreements.length !== 0) {
    return <div id="list"
      className={"-oneX-col-lg-6 -oneX-col-md-8 -oneX-col-sm-10"}
      style={{ marginBottom: "128px" }}
    >
      <h1 id={"list-header"} style={{ textAlign: "left", marginTop: isDesktop ? "56px" : isTablet ? "48px" : isMobile ? "40px" : "40px" }}>Which ID cards would you like to receive?</h1>

      <SubHeadingDiv className={"-oneX-body--intro-sm"} style={{ justifyContent: "left" }}>
        Electronic proof of insurance (e-cards or PDFs) are accepted by law enforcement officers in most states.
      </SubHeadingDiv>
      <div className={"-oneX-heading--h5"} style={{ marginBottom: "16px",justifyContent: "left" }}>
        State Farm® Auto
      </div>
      {noAgreements ? null : (
        <OneXCheckboxGroup
          id={"id-cards-checkbox-list"}
          type="stacked"
          options={agreements}
          changeHandler={(value) => {
            let tempAgreements = selectedAgreements.slice();
            let valueIndex = tempAgreements.indexOf(value);
            if (valueIndex === -1) {
              tempAgreements.push(value);
              setSelectionCnt(selectionCnt + 1);
            } else {
              tempAgreements.splice(valueIndex, 1);
              setSelectionCnt(selectionCnt - 1);
            }
            setSelectedAgreements(tempAgreements);
          }}
        />
      )}

      <div className={"-oneX-heading--h5"} style={{ justifyContent: "left", marginTop: "48px", marginBottom: "16px" }}>
        State Farm Classic+™
      </div>

      <div className={"-oneX-typography-variant1"}>
        <HagertyComponent
          args={hagertyAgreements}
        />
      </div>

      <div style={{ marginLeft: "0px", textAlign: "left", marginTop: "16px", marginBottom: "48px" }}
        className="-oneX-body--secondary"
      >Choose how to receive your State Farm Classic+™ ID card:
        <ul>
          <li > <a id="lite-auth-hagerty-list-screen" href="https://proofing.statefarm.com/login-interceptor/login" style={{ textDecoration: 'none', color: "red" }}>Log in</a> and be redirected to Hagerty®</li>
          <li>Contact your agent</li>
        </ul>
      </div>

      <div style={{ marginBottom: "48px" }}>
        <OneXButton
          id={"list-primary-button"}
          type={"primary"}
          clickHandler={handleClick}
          disabled={selectionCnt < 1}
        >
          Continue
        </OneXButton>
      </div>
      <ContinueDisclaimer />
    </div>
  }
  return <div id="list"
    className={"-oneX-col-lg-6 -oneX-col-md-8 -oneX-col-sm-10"}
    style={{ marginBottom: "128px" }}
  >
    <h1 id={"list-header"} className={"-oneX-heading-h1"} style={{ textAlign: "left", marginTop: isDesktop ? "56px" : isTablet ? "48px" : isMobile ? "40px" : "40px" }}>Which ID cards would you like to receive?</h1>
    <SubHeadingDiv className={"-oneX-body--intro-sm"} style={{ justifyContent: "left" }}>
      Electronic proof of insurance (e-cards or PDFs) are accepted by law enforcement officers in most states.
    </SubHeadingDiv>
    {noAgreements ? null : (
      <OneXCheckboxGroup
        id={"id-cards-checkbox-list"}
        type="stacked"
        options={agreements}
        changeHandler={(value) => {
          let tempAgreements = selectedAgreements.slice();
          let valueIndex = tempAgreements.indexOf(value);
          if (valueIndex === -1) {
            tempAgreements.push(value);
            setSelectionCnt(selectionCnt + 1);
          } else {
            tempAgreements.splice(valueIndex, 1);
            setSelectionCnt(selectionCnt - 1);
          }
          setSelectedAgreements(tempAgreements);
        }}
      />
    )}

    <div style={{ marginBottom: "48px", marginTop: "40px" }}>
      <OneXButton
        id={"list-primary-button"}
        type={"primary"}
        clickHandler={handleClick}
        disabled={selectionCnt < 1}
      >
        Continue
      </OneXButton>
    </div>
    <ContinueDisclaimer />
  </div>
};

export default IdCardsList;

